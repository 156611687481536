@use '@circutor/ui/scss/abstracts/_cds-vars' as *;
@use '@circutor/ui/scss/02-tools/_cds-mixins-typography.scss' as *;

.container {
  @include cds-paragraph-xs(bold);

  display: flex;
  column-gap: 4px;
  align-items: center;
  padding: 0px $cds-spacing-50 0px 4px;

  border-radius: 20px;

  &--success {
    border: 1px solid var(--cds-color-feedback-success-pale);
    background: var(--cds-color-feedback-success-pale);
    color: var(--cds-color-feedback-success-strong);
  }

  &--info {
    border: 1px solid var(--cds-color-feedback-information-pale);
    background: var(--cds-color-feedback-information-pale);
    color: var(--cds-color-feedback-information-strong);
  }

  &--alert {
    border: 1px solid var(--cds-color-feedback-alert-pale);
    background: var(--cds-color-feedback-alert-pale);
    color: var(--cds-color-feedback-alert-strong);
  }

  &--error {
    border: 1px solid var(--cds-color-feedback-error-pale);
    background: var(--cds-color-feedback-error-pale);
    color: var(--cds-color-feedback-error-strong);
  }
}

.clickable {
  cursor: pointer;
}
