@use '@circutor/ui/scss/abstracts/_cds-vars' as *;
@use '@circutor/ui/scss/02-tools/_cds-mixins-typography.scss' as *;

.modal {
  &__content {
    display: flex;
    flex-direction: column;
    row-gap: $cds-spacing-50;
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    column-gap: $cds-spacing-50;
  }
}

.activator {
  display: flex;

  & > button {
    @include cds-paragraph-s;

    cursor: pointer;

    &:hover {
      @media only screen and (min-width: 1025px) {
        color: var(--cds-color-text-default--hover);
      }
    }
  }
}