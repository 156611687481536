@use '@circutor/ui/scss/abstracts/_cds-vars' as *;
@use '@circutor/ui/scss/02-tools/_cds-mixins-typography.scss' as *;

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  & .info {
    display: flex;
    flex-direction: column;
    min-width: 50%;
    gap: $cds-spacing-50;
    flex: 1;
    overflow: hidden;

    & > h4 {
      @include cds-paragraph-m(bold);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    & .list {
      display: flex;
      overflow: hidden;

      & > span {
        display: flex;
        align-items: center;
        overflow: hidden;

              
        & .name {
          @include cds-paragraph-s;
          color: var(--cds-color-text-subtle);
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

    }
  }

  & .actions {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

}