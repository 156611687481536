@use '@circutor/ui/scss/abstracts/_cds-vars' as *;


.wks-reports-list {
  display: flex;
  flex-direction: column;
  row-gap: $cds-spacing-25;

  &__loader {
    padding: 3rem 0 0 0;
  }
}
