@use '@circutor/ui/scss/abstracts/_cds-vars' as *;

.inputs-wrapper {
  display: flex;
  flex-direction: column;
  row-gap: $cds-spacing-50;

  & > div {
    display: flex;
  }
}