@use '@circutor/ui/scss/abstracts/_cds-vars' as *;
@use '@circutor/ui/scss/02-tools/_cds-mixins-typography.scss' as *;

.container {
  display: flex;
  flex-direction: column;
  padding: $cds-spacing-50;
  border-radius: 4px;
  gap: $cds-spacing-50;
  border: 1px solid var(--cds-color-border-default);
  background: var(--cds-color-background-default);
}