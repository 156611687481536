@use '@circutor/ui/scss/abstracts/_cds-vars' as *;
@use '@circutor/ui/scss/02-tools/_cds-mixins-typography.scss' as *;

.container {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  row-gap: $cds-spacing-50;
  


  & > div {
    display: flex;
    border-radius: 4px;
    background: var(--cds-color-background-default);

    @media screen and (min-width: 1025px) {
      width: 500px;
    }
  }

  & .login {
    flex-direction: column;
    row-gap: $cds-spacing-100;
    padding: $cds-spacing-250 $cds-spacing-150;

    & > h5 {
      @include cds-paragraph-l(bold);
    }

    & > .forms {
      display: flex;
      flex-direction: column;
      row-gap: $cds-spacing-100;
    }

    & > .actions {
      display: flex;
      justify-content: flex-end;
    }

    & > .checkbox-group {
      display: flex;
      flex-direction: column;
      row-gap: $cds-spacing-50;

      & .checkbox-label {
        @include cds-paragraph-s;
        color: var(--cds-color-text-subtle);
        
        & > a {
          color: var(--cds-color-text-default);
        }
      }
    }
  }

  & .signup-link {
    width: 100%;
    column-gap: $cds-spacing-50;
    padding: $cds-spacing-100 $cds-spacing-150;

    @include cds-paragraph-m;

    & > span {
      color: var(--cds-color-text-subtle);
    }
  }
}