@use '@circutor/ui/scss/abstracts/_cds-vars' as *;

.container{
  width: 100%;
  display: flex;
  flex-direction: row;

  & > form {
    width: 100%;
  }
}

.inputs-wrapper {
  display: flex;
  flex-wrap: wrap;
  column-gap: $cds-spacing-250;
  row-gap: $cds-spacing-50;

  & > * {
    align-items: center;
    width: 100%;

    @media only screen and (min-width: 1025px) {
      min-width: unset;
    }
  }
}