@use '@circutor/ui/scss/abstracts/_cds-vars' as *;
@use '@circutor/ui/scss/02-tools/_cds-mixins-typography.scss' as *;

.container {
  display: flex;
  gap: $cds-spacing-100;
  flex-wrap: wrap;

  & > * {
    width: 100%;

    @media screen and (min-width: 1600px) {
      flex: 1;
      min-width: 540px;
    }
  }
}

.notice {
  text-align: center;

  & a {
    @include cds-paragraph-m;
    font-weight: 700;
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-decoration-skip-ink: auto;
    text-decoration-thickness: auto;
    text-underline-offset: auto;
    text-underline-position: from-font;
  }
}