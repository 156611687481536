@use '@circutor/ui/scss/abstracts/_cds-vars' as *;
@use '@circutor/ui/scss/02-tools/_cds-mixins-typography.scss' as *;

.modal {
  &__content {
    max-height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    column-gap: $cds-spacing-50;
  }
}

.new {
  display: flex;
  padding: $cds-spacing-100;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 100%;

  border-radius: var(--cds-border-radius-50, 8px);
  border: 1px dashed var(--cds-color-border-default);
  background: var(--cds-color-background-default);

  &:hover {
    background: var(--cds-color-background-soft);
  }

  & > div {
    display: flex;
    align-items: center;
    gap: $cds-spacing-50;
    @include cds-paragraph-m;

    & > span {
      color: var(--cds-color-text-subtle);
    }
  }
}