@use '@circutor/ui/scss/abstracts/_cds-vars' as *;
@use '@circutor/ui/scss/02-tools/_cds-mixins-typography.scss' as *;

.container {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  flex-direction: column;
  row-gap: $cds-spacing-100;
  align-items: flex-start;
  max-width: 800px;
  margin: auto;
 
  & > h6 {
    @include cds-paragraph-xs(bold);
  }

  & > p {
    @include cds-paragraph-m;
  }
}

.modal {
  &__content {
    display: flex;
    flex-direction: column;
    row-gap: $cds-spacing-150;
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    column-gap: $cds-spacing-50;
  }
}