@use '@circutor/ui/scss/abstracts/_cds-vars' as *;

.wks-timeline {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding-left: $cds-spacing-100;
  
  &__item {
    width: 100%;
    min-width: 260px;

    &__status {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-top: $cds-spacing-100;
      &::after {
        content: "";
        width: 100%;
        height: 2px;
        display: block;
        background-color: var(--cds-color-background-deep);
      }
    }

    &__content {
      width: calc(100% - $cds-spacing-150);
      padding: $cds-spacing-50;
      &__title {
        width: 100%;
        font-size: 14px;
        font-weight: bold;
        margin-bottom: $cds-spacing-50;
        overflow: hidden;
        text-wrap: nowrap;
        text-overflow: ellipsis;
      }
      &__subtitle {
        color: var(--cds-color-text-subtle);
        font-size: 14;
      }
      &__footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }

    &:last-child {
      &>*:first-child {
        &::after {
          background-color: transparent;
        }
      }
    }
  }
}

@media screen and (min-width: 1025px) {
  .wks-timeline {
    padding-left: 0;
    flex-direction: column;
    &__item {
      display: flex;
      min-width: unset;
      &__status {
        flex-direction: column;
        margin-top: $cds-spacing-0;
        &::after {
          content: "";
          width: 2px;
          height: 100%;
        }
      }
      &__content {
        padding: $cds-spacing-0 $cds-spacing-50 $cds-spacing-100;
      }
    }
  }
}




