@use '@circutor/ui/scss/abstracts/_cds-vars' as *;
@use '@circutor/ui/scss/02-tools/_cds-mixins-typography.scss' as *;

.container {
  width: 100%;
  row-gap: $cds-spacing-100;
}

.wks-alarm__main {
  display: flex;
  flex-direction: column;

  &__content {
    padding-top: $cds-spacing-100;
    padding-bottom: $cds-spacing-100;
    overflow: auto;

    & > p {
      margin-bottom: $cds-spacing-100;
    }
  }
}