@use '@circutor/ui/scss/abstracts/_cds-vars' as *;
@use '@circutor/ui/scss/02-tools/_cds-mixins-typography.scss' as *;

.container {
  display: flex;
  flex-direction: column;
  border-radius: $cds-spacing-50;
  padding: $cds-spacing-50;
  row-gap: $cds-spacing-50;
  justify-content: space-between;

  &.active {
    border: 1px solid var(--cds-color-feedback-success-main);
    background: var(--cds-color-feedback-success-pale);
  }

  &.inactive {
    border: 1px solid var(--cds-color-border-default);
    background: var(--cds-color-background-soft);
  }

  & > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  & > p {
    @include cds-paragraph-s;
  }
}

.title {
  @include cds-paragraph-m(bold);
  &.active {
    color: var(--cds-color-feedback-success-strong);
  }

  &.inactive {
    color: var(--cds-color-text-subtle);
  }
}

.chip {
  display: flex;
  column-gap: 4px;
  border-radius: $cds-spacing-150;
  align-items: center;
  padding-right: $cds-spacing-100;

  @include cds-paragraph-s;

  &.active {
    border: 1px solid var(--cds-color-feedback-success-main);
    background: var(--cds-color-feedback-success-main);
    color: var(--cds-color-always-white);
  }

  &.inactive {
    border: 1px solid var(--cds-color-border-default);
    background: var(--cds-color-border-default);
  }

}

.active {
  color: var(--cds-color-always-white);
}
