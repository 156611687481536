@use '@circutor/ui/scss/abstracts/_cds-vars' as *;
@use '@circutor/ui/scss/02-tools/_cds-mixins-typography.scss' as *;

.wks-report-summary-card {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: $cds-spacing-25;
  padding: $cds-spacing-50;

  border: 1px solid var(--cds-color-border-default);
  background: var(--cds-color-background-default);
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.10);


  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: $cds-spacing-50;

    &__title {
      width: 100%;
      display: flex;
      overflow: hidden;
      align-items: center;
      
      
      & > h5 {
        flex: 1;
        overflow: hidden;
        @include cds-paragraph-l(bold);
        overflow: hidden;
        text-overflow: ellipsis;
        text-wrap: nowrap;
        max-width: 100%;
      }

      &__sub-title {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        column-gap: $cds-spacing-50;
      }

      @media only screen and (min-width: 1025px) {
        flex: 1;
        width: unset;
        flex-direction: column;
        align-items: flex-start;
      }
    }

    &__suffix {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
      row-gap: $cds-spacing-50;
  
      
  
      @media only screen and (min-width: 1025px) {
        width: unset;
        column-gap: $cds-spacing-25;
        justify-content: unset;
        flex-direction: row-reverse;
      }
    }

    @media only screen and (min-width: 1025px) {
      column-gap: $cds-spacing-25;
      flex-direction: row;
    }
  }



  &__date {
    color: var(--cds-color-text-subtle);
    strong { 
      color: var(--cds-color-text-subtle);
      font-weight: bolder;
    }
  }

  &__actions {
    display: flex;
    column-gap: $cds-spacing-25;
  }

  @media only screen and (min-width: 1025px) {
    column-gap: $cds-spacing-25;
    flex-direction: row;
  }
}