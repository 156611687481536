@use '@circutor/ui/scss/abstracts/_cds-vars' as *;
@use '@circutor/ui/scss/02-tools/_cds-mixins-typography.scss' as *;

.container {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  max-width: 800px;
  margin: auto;
 
  & > div {
    width: 100%;
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    row-gap: $cds-spacing-50;
    margin: auto;
    justify-content: center;
  }
}

.list {
  height: calc(100% - 48px);
}

.user-column {
  padding: $cds-spacing-50 0;
  display: flex;
  flex-direction: column;
  height: 100%;

  & > span {
    height: 20px;
    @include cds-paragraph-m;
    color: var(--cds-color-text-subtle);

    &.email {
      color: var(--cds-color-text-default, #5F656A);

    }
  }
}

.switch-column {
  display: flex;
  height: 100%;
}

.actions-column {
  display: flex;
  align-items: center;
  height: 100%;
}

.actions {
  display: flex;
  column-gap: $cds-spacing-50;
}