@use '@circutor/ui/scss/abstracts/_cds-vars' as *;
@use '@circutor/ui/scss/02-tools/_cds-mixins-typography.scss' as *;


.container {
  display: flex;
  gap: $cds-spacing-50;

}

.menu {
  border-bottom: none;

  & .submenu {
    color: var(--cds-color-text-subtle);
    @include cds-paragraph-m();
  }

  :global {
    .ant-menu-title-content {
      display: flex;
    }
  }  
}

