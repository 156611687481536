@use '@circutor/ui/scss/abstracts/_cds-vars' as *;
@use '@circutor/ui/scss/02-tools/_cds-mixins-typography.scss' as *;

.container {
  display: flex;
  border-radius: 4px;
  background: var(--cds-color-feedback-alert-pale);
  padding: $cds-spacing-100;
  gap: $cds-spacing-50;
}

.content {
  flex: 1;

  & > h5 {
    color: var(--cds-color-feedback-alert-strong);
    @include cds-paragraph-m(bold);
  }

  & > p {
    color: var(--cds-color-feedback-alert-strong);
    @include cds-paragraph-m;

    a {
      text-decoration: underline;
    }
  }
}
