@use '@circutor/ui/scss/abstracts/_cds-vars' as *;

.container {
  padding-left: $cds-spacing-50;
  padding-top: $cds-spacing-50;

  & > div {
    border-left: 1px solid var(--cds-color-border-default);
    padding-left: $cds-spacing-150;
  }
}

.assets {
  display: flex;
  flex-direction: column;
  row-gap: $cds-spacing-50;
}

.actions {
  display: flex;
  flex-wrap: wrap;
  gap: $cds-spacing-50;
  padding: $cds-spacing-50 0;
}

