@use '@circutor/ui/scss/abstracts/_cds-vars' as *;
@use '@circutor/ui/scss/02-tools/_cds-mixins-typography.scss' as *;

.inputContainer {
  display: flex;
  flex-direction: column;
  row-gap: 2px;

  
}

.inputLabel {
  @include cds-paragraph-m; 
}

.cdsTextfieldField {
  border-radius: 0.25rem;
  padding: 0.5rem;
  width: 100%;
  height: 2.5rem;
  cursor: pointer;
  color: #20262c;
  border: 1px solid #d9d9d9;
  appearance: none;
  -webkit-appearance: none;
}

.cdsNumberField {
  border-radius: 0.25rem;
  padding: 0.5rem;
  width: 100%;
  height: 2.5rem;
  cursor: pointer;
  border: 1px solid #d9d9d9;
  appearance: none;
  background: var(--cds-color-background-default);

  -webkit-appearance: none;

  :global {
    .ant-input-number-handler-wrap {
      display: none;
    }
    .ant-input-number-input {
      padding: 0
    }

  }
}


.cdsDropdown {
  height: 40px;

  :global {
    .ant-select-selection-item,
    .ant-select-item.ant-select-item-option {
      font-size: 1rem;
      color: var(--cds-color-text-default);
    }
  }
}

.errorMessages {
  text-align: end;
  width: 100%;
  color: #ff4d4f;
}
