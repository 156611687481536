@use '@circutor/ui/scss/abstracts/_cds-vars' as *;
@use '@circutor/ui/scss/02-tools/_cds-mixins-typography.scss' as *;

.container {
  width: 100%;
  max-width: 820px;
  margin: auto;
  height: 100%;
  overflow: hidden;
}

.project-basic-form {
  display: flex;
  flex-direction: column;
  row-gap: $cds-spacing-50;

  & > div:last-child {
    display: flex;
    justify-content: flex-end;
  }
}

.project-module-info {
  & > h3 {
    padding: $cds-spacing-50 0;

    @include cds-paragraph-m;
    color: var(--cds-color-text-subtle);

    border-bottom: 1px solid var(--cds-color-border-default);
  }

  & > div {
    display: flex;
    flex-wrap: wrap;
    gap: $cds-spacing-50;
    padding: $cds-spacing-50 0;

    
    & > * {
      flex: 1;
      min-width: 260px;
    }
  }
}