@use '@circutor/ui/scss/abstracts/_cds-vars' as *;
@use '@circutor/ui/scss/02-tools/_cds-mixins-typography.scss' as *;

.modal {
  &__content {
    max-height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    column-gap: $cds-spacing-50;
  }
}
