@use '@circutor/ui/scss/abstracts/_cds-vars' as *;

.container {
  width: 100%;
  max-width: 820px;
  margin: auto;
  height: 100%;
  overflow: hidden;
}

.project-basic-form {
  display: flex;
  flex-direction: column;
  row-gap: $cds-spacing-50;
  padding-bottom: $cds-spacing-100;

  & > div:last-child {
    display: flex;
    justify-content: flex-end;
  }
}

.special-inputs-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: $cds-spacing-50;

  & > * {
    flex: 1;
    min-width: 300px;

    @media only screen and (min-width: 1025px) {
      max-width: calc(50% - $cds-spacing-50);
    }
  }
}