@use '@circutor/ui/scss/abstracts/_cds-vars' as *;

.modal {
  &__footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    column-gap: $cds-spacing-50;
  }

  &__content {
    display: flex;
    flex-direction: column;
    row-gap: $cds-spacing-50;
    overflow: hidden;

    & pre {
      height: 100%;
      padding: $cds-spacing-50 $cds-spacing-100;
      border-radius: 8px;
      border: 1px solid var(--cds-color-text-surface);
    }
  }
}