@use '@circutor/ui/scss/abstracts/_cds-vars' as *;
@use '@circutor/ui/scss/02-tools/_cds-mixins-typography.scss' as *;

.container {
  display: flex;
  flex-direction: column;
  gap: $cds-spacing-100;
}

.tupla {
  display: flex;
  flex-direction: column;
  gap: $cds-spacing-25;

  & > h6 {
    @include cds-paragraph-s(bold);
  }

  & > p {
    @include cds-paragraph-s;
  }
}