@use '@circutor/ui/scss/abstracts/_cds-vars' as *;

.wks-tag-ghost {
  display: flex;
  align-items: center;
  color: var(--cds-color-text-subtle);

  &__icon {
    margin-right: 0.25rem;
  }

  strong { 
    color: var(--cds-color-text-subtle);
    font-weight: bolder;
    margin-right: 0.25rem;
  }
}
