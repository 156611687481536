@use '@circutor/ui/scss/abstracts/_cds-vars' as *;

.wks-alarm__sidebar {
  display: flex;
  flex-direction: column;
  gap: $cds-spacing-0;
  padding: 0.75rem 0;
  overflow: auto;

  &__header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: $cds-spacing-0;
    padding: 0 $cds-spacing-100;
    

    h5 {
      padding-right: $cds-spacing-100;
    }

    @media screen and (min-width: 1025px) {
      padding: 0;
    }
  }

  &__timeline {
    width: 100%;
    overflow: hidden;
  }
}

.alarm__event__footer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  color: var(--cds-color-text-subtle);
  font-size: 14;
}


.wks-avatar {
  min-width: $cds-spacing-150;
  min-height: $cds-spacing-150;
  border-radius: $cds-spacing-150;
  font-size: $cds-spacing-50;
  display: flex;
  align-items: center;
  justify-content: center;

  &--color-info {
    background-color: var(--cds-color-feedback-information-main);
    color: var(--cds-color-always-white);
  }
  &--color-error {
    background-color: var(--cds-color-feedback-error-main);
    color: var(--cds-color-always-white);
  }
  &--color-success {
    background-color: var(--cds-color-feedback-success-main);
    color: var(--cds-color-always-white);
  }
}

@media screen and (min-width: 1025px) {
  .wks-alarm__sidebar {
    min-width: 300px;
    padding: $cds-spacing-0 $cds-spacing-50 $cds-spacing-0;
    gap: $cds-spacing-50;
    padding: 0;
  }
}



