@use '@circutor/ui/scss/abstracts/_cds-vars' as *;


.wks-account-page {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: $cds-spacing-100;

  &__tabs {
    &__tab {
      margin: auto;
      padding-top: $cds-spacing-100;
      height: calc(100dvh - 130px);
      max-width: 800px;
    }
  }
}
