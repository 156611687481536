@use '@circutor/ui/scss/abstracts/_cds-vars' as *;
@use '@circutor/ui/scss/02-tools/_cds-mixins-typography.scss' as *;

.wks-asset-details-section {
  display: flex;
  flex-direction: column;
  row-gap: $cds-spacing-50;
  overflow: hidden;

  & > h5 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    @include cds-paragraph-m(bold);
    color: var(--cds-color-text-default);
    padding-left: $cds-spacing-50;

    & > div {
      display: flex;
      cursor: pointer;
    }
  }

  &__info-banner {
    padding-right: $cds-spacing-50;
  }

  &__coummunication-status-indicator {
    display: flex;
    align-items: center;

    &--information {
      color: var(--cds-color-feedback-information-pale)
    }

    &--alert {
      color: var(--cds-color-feedback-alert-main)
    }

    &--error {
      color: var(--cds-color-feedback-error-main)
    }

    &--success {
      color: var(--cds-color-feedback-success-main);
    }
  }

  &--spaced {
    padding: $cds-spacing-100
  }
}

.device {
  padding: 0 $cds-spacing-50;

  display: flex;
  flex-direction: column;
  gap: $cds-spacing-100;

  & > h6 {
    padding-top: $cds-spacing-100;
    border-top: 1px solid var(--cds-color-border-default, #CFD0D1);
    @include cds-paragraph-m(bold);
  }

  & > div {
    display: flex;
  }
}

.link {
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-skip-ink: auto;
  text-decoration-thickness: auto;
  text-underline-offset: auto;
  text-underline-position: from-font;
}