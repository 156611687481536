@use '@circutor/ui/scss/abstracts/_cds-vars' as *;

.wks-steps-chart-manager {
  display: flex;
  flex-direction: column;
  row-gap: $cds-spacing-100;

  &__chart {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 30dvh;
    flex: 1;
  }
}
