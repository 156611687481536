@use '@circutor/ui/scss/abstracts/_cds-vars' as *;

.header {
  display: flex;
  font-weight: bold;
  padding-top: $cds-spacing-50;
  padding-bottom: $cds-spacing-100;

  .wks-alarm-layout__return-link {
    display: flex;
    gap: 0.4rem;
    padding: 0 $cds-spacing-100;
  }
}

.wks-alarm {
  display: flex;
  flex-direction: column;
  height: calc(100% - 45px);
  overflow: auto;

  &__details {
    flex: 1;
    display: flex;
    flex-direction: column;
    row-gap: $cds-spacing-50;
    padding: 0 $cds-spacing-100;

    &__recommendation {
      padding-bottom: $cds-spacing-100;
    }

    @media screen and (min-width: 1025px) {
      overflow: auto;
      height: 100%;
      padding-bottom: $cds-spacing-100;
    }
  }

  &__events-list {
    @media screen and (min-width: 1025px) {
      display: flex;
      height: 100%;
    }
  }

  @media screen and (min-width: 1025px) {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    column-gap: $cds-spacing-250;
    overflow: hidden;
  }
}






