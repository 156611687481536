@use '@circutor/ui/scss/abstracts/_cds-vars' as *;

.container {
  flex: 1;
  display: flex;
  flex-direction: column;
  row-gap: $cds-spacing-50;
  padding: 0 $cds-spacing-100;
  overflow: auto;
  height: 100%;

  @media screen and (min-width: 1025px) {
    height: 100%;
    padding-bottom: $cds-spacing-100;
  }
}
