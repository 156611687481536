@use '@circutor/ui/scss/abstracts/_cds-vars' as *;
@use '@circutor/ui/scss/02-tools/_cds-mixins-typography.scss' as *;

.wks-alarm__main {
  display: flex;
  flex-direction: column;

  &__preheader {
    .cds-button {
      padding: $cds-spacing-0;
    }
  }
  &__header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    border-bottom: solid 1px var(--cds-color-border-default);
    padding-bottom: $cds-spacing-100;

    &__highlights {
      width: 100%;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      column-gap: $cds-spacing-50;
    }

    &__title {
      overflow: hidden;
      margin-top: 0.5rem;
      h2 {
        @include cds-head-s();
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &__actions {
      display: flex;
      gap: $cds-spacing-50;
    }

    &__content {
      padding-top: $cds-spacing-100;
    }
  }
  &__content {
    padding-top: $cds-spacing-100;
    padding-bottom: $cds-spacing-100;
    overflow: auto;

    & > p {
      margin-bottom: $cds-spacing-100;
    }
  }
}



