@use '@circutor/ui/scss/abstracts/_cds-vars' as *;

.container {
  width: 100%;
}

.inputs-wrapper {
  display: flex;
  flex-direction: column;
  gap: $cds-spacing-50;

  & > div {
    display: flex;
    flex-wrap: wrap;
    gap: $cds-spacing-50;

    & > * {
      flex: 1;
      min-width: 300px;
    }
  }
}