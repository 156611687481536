@use '@circutor/ui/scss/abstracts/_cds-vars' as *;

.wks-asset-metrics-line-chart-manager__filter{
  width: 100%;
  display: flex;
  flex-direction: row;

  & > form {
    width: 100%;
  } 

  &__container {
    display: flex;
    flex-wrap: wrap;
    column-gap: $cds-spacing-250;
    row-gap: $cds-spacing-50;

    & > * {
      flex: 1;
      align-items: center;
      min-width: 240px;
      @media only screen and (min-width: 1025px) {
        min-width: unset;
      }
    }

    & > .selector {
      min-width: 240px;
    }

    & > .chart-mode-selector {
      padding-top: $cds-spacing-100;
      display: flex;
      column-gap: $cds-spacing-50;
    }
  }
}
