@use '@circutor/ui/scss/abstracts/_cds-vars' as *;

.modal {
  &__content {
    display: flex;
    flex-direction: column;
    row-gap: $cds-spacing-50;
  }
}

