@use '@circutor/ui/scss/abstracts/_cds-vars' as *;
@use '@circutor/ui/scss/02-tools/_cds-mixins-typography.scss' as *;

.container{
  width: 100%;
  display: flex;
  flex-direction: row;

  & > form {
    width: 100%;
  } 
}

.inputs-wrapper {
  display: flex;
  flex-wrap: wrap;
  column-gap: $cds-spacing-250;
  row-gap: $cds-spacing-50;

  & > * {
    display: flex;
    gap: $cds-spacing-50;
    align-items: flex-start;
    width: 100%;
    flex-wrap: wrap;

    & > * {
      flex: 1;
      min-width: 280px;

      @media only screen and (min-width: 1025px) {
        min-width: unset;
      }
    }

    &:last-child > *:last-child {
      min-height: 90px;
    }
  }
}

.scheduleInput {
  display: flex;
  flex-direction: column;
  @include cds-paragraph-s;

  & > * {
    width: 100%;
  }
}