@use '@circutor/ui/scss/abstracts/_cds-vars' as *;
@use '@circutor/ui/scss/02-tools/_cds-mixins-typography.scss' as *;

.container {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  row-gap: $cds-spacing-50;
  


  & > div {
    display: flex;
    border-radius: 4px;
    background: var(--cds-color-background-default);

    @media screen and (min-width: 1025px) {
      width: 500px;
    }
  }

  & .restore {
    flex-direction: column;
    row-gap: $cds-spacing-100;
    padding: $cds-spacing-250 $cds-spacing-150;

    & > h5 {
      @include cds-paragraph-l(bold);
    }

    & > .forms {
      display: flex;
      flex-direction: column;
      row-gap: $cds-spacing-100;
    }

    & > .actions {
      display: flex;
      justify-content: flex-end;
    }
  }

}