@use '@circutor/ui/scss/abstracts/_cds-vars' as *;
@use '@circutor/ui/scss/02-tools/_cds-mixins-typography.scss' as *;

.wks-modal {
  display: flex;
  flex-direction: column;
  row-gap: $cds-spacing-150;
  max-height: 85dvh;

  &__header {
    display: flex;
    align-items: center;
    
    &__title {
      display: flex;
      flex: 1;
      align-items: center;
      column-gap: $cds-spacing-25;
      color: var(--cds-color-text-default);

      & > h4 {
        @include cds-paragraph-l(bold);
      }
    }

    & > span {
      color: var(--cds-color-text-subtle);
      cursor: pointer;
      
      &:hover {
        color: var(--cds-color-text-subtle--hover);
      }
    } 
  }

  &__content {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
  }

  :global {
    .ant-modal-content {
      background-color: var(--cds-color-background-default);
    }
  }
}